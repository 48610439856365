import React from 'react';
import './mobileLogo.css';
import steelifyIcon from './steelify_icon.png';

const MobileLogo = () => {
  return (
    <div className="mobile-logo-container">
      <img src={steelifyIcon} alt="Steelify Logo" className="mobile-logo" />
    </div>
  );
};

export default MobileLogo;