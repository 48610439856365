import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../Redux/Slices/userSlice'; // Redux'taki user bilgisini güncellemek için
import RegisterModal from './registerModal'; // Register modalı
import { API_BASE_URL } from '../config';

const LoginModal = ({ visible, onCancel }) => {
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false); // Register modal durumu
  const dispatch = useDispatch();

  const handleLogin = async (values) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, values);
      if (response.data && response.data.data) {
        const { data, access_token } = response.data.data;
        const userId = data.id;
        const odooId = data.odoo_id;
        dispatch(setUser({ user: data, email: data.email, token: access_token, id: userId, odooId }));

        localStorage.setItem('accessToken', access_token);
        message.success('Login successful!');
        onCancel(); // Modalı kapat
      } else {
        message.error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      message.error('An error occurred during login. Please try again.');
    }
  };

  const handleRegisterClick = (e) => {
    e.preventDefault(); // Yönlendirmeyi engelle
    setIsRegisterModalVisible(true); // Register modalını aç
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
      >
        <h2>Login</h2>
        <Form
          name="login"
          onFinish={handleLogin}
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Don't have an account?{' '}
          <a href="#" onClick={handleRegisterClick}>Click to Register</a>
        </div>
      </Modal>

      {/* Register modalını ekliyoruz */}
      <RegisterModal
        visible={isRegisterModalVisible}
        onCancel={() => setIsRegisterModalVisible(false)}
        onRegisterSuccess={() => setIsRegisterModalVisible(false)} // Kayıt başarılı olduğunda register modalı kapanacak
      />
    </>
  );
};

export default LoginModal;
