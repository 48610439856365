import React from "react";
import "./laserCutting.css";
import Trustpilot from "../../Homepage/Trustpilot/trustpilot";
import { Link } from "react-router-dom";

function LaserCutting() {
  return (
    <div className="laser-container">
      <div className="laser-left">
        <h1>Laser Cutting</h1>
        <div className="laser-content">
          <p>
            We provide fast, precise laser cutting services for a wide range of
            materials, helping you stay ahead in production.
          </p>
          <ul>
            <li>Clean and accurate cuts with minimal waste</li>
            <li>
              Perfect for detailed designs and the exact tolerances your project
              demands
            </li>
            <li>
              Fast turnaround for both prototypes and large production orders
            </li>
          </ul>
        </div>
        {/* <div className="capabilities-trustpilot-container">
          <Trustpilot />
        </div> */}
        <div className="laser-call-to-action">
          <p className="call-to-action-text">
            Need laser-cut components quickly? Send us your designs, and we’ll deliver accurate cuts with speed and efficiency.
          </p>
          <Link to="/" className="call-to-action-button">
            Get Your Quote!
          </Link>
        </div>
      </div>
      <div className="laser-right">
        <img
          src="https://yenastorage.blob.core.windows.net/steelify/steelify_image_laser_cutting.png"
          alt="Laser Cutting"
        />
      </div>
    </div>
  );
}

export default LaserCutting;
