import React, { useState, useEffect } from "react";
import "./process.css";

const Process = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="process-container">
      <img
        src={
          isMobile
            ? "https://yenastorage.blob.core.windows.net/steelify/steelify_image_processes_mobile.png"
            : "https://yenastorage.blob.core.windows.net/steelify/steelify_image_processes_web.png"
        }
        alt="Steelify Processes"
        className="process-image"
      />
    </div>
  );
};

export default Process;
