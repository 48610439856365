import React from "react";
import "./bending.css";
import Trustpilot from "../../Homepage/Trustpilot/trustpilot";
import { Link } from "react-router-dom";

function Bending() {
  return (
    <div className="bending-container">
      <div className="bending-left">
        <h1>Bending</h1>
        <div className="bending-content">
          <p>
            Our bending services ensure precise metal shaping for any project,
            helping you achieve the exact forms and geometries required.
          </p>
          <ul>
            <li>Hydraulic presses for accurate bends</li>
            <li>Capable of handling simple to complex shapes</li>
            <li>Consistent, high-quality results for projects of all sizes</li>
          </ul>
        </div>
        {/* <div className="capabilities-trustpilot-container">
          <Trustpilot />
        </div> */}
        <div className="bending-call-to-action">
          <p className="call-to-action-text">
            Looking for precision in metal bending? Contact us now to get a
            custom quote and ensure your project is perfectly shaped.
          </p>
          <Link to="/" className="call-to-action-button">
            Get Your Quote!
          </Link>
        </div>
      </div>
      <div className="bending-right">
        <img
          src="https://yenastorage.blob.core.windows.net/steelify/steelify_image_bending.png"
          alt="Bending"
        />
      </div>
    </div>
  );
}

export default Bending;
