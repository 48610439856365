import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import "./stats.css";

const statsData = [
  {
    number: "1000000",
    suffix: "+",
    label: "Parts delivered",
    icon: "https://yenastorage.blob.core.windows.net/steelify/steelify_icon_parts_delivered.png",
  },
  {
    number: "400",
    suffix: "+",
    label: "Shipment",
    icon: "https://yenastorage.blob.core.windows.net/steelify/steelify_icon_shipment.png",
  },
  {
    number: "18",
    suffix: " Years",
    label: "Manufacturing Experience",
    icon: "https://yenastorage.blob.core.windows.net/steelify/steelify_icon_manufacturing_experience.png",
  },
  {
    number: "100",
    suffix: "+",
    label: "Factories",
    icon: "https://yenastorage.blob.core.windows.net/steelify/steelify_icons_factories.png",
  },
];

const Stats = () => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !hasAnimated) {
          setHasAnimated(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <div className="stats-container" ref={statsRef}>
      {statsData.map((stat, index) => (
        <div key={index} className="stat-item">
          <div className="stat-icon-container">
            <img src={stat.icon} alt={stat.label} className="stat-icon" />
          </div>
          <div className="stat-content">
            <p className="stat-number">
              {hasAnimated ? (
                <CountUp end={parseInt(stat.number)} duration={3} />
              ) : (
                0
              )}
              {stat.suffix}
            </p>
            <p className="stat-label">{stat.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stats;
