import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import "./certification.css";

const Certification = () => {
  return (
    <div className="certification-container">
      <FontAwesomeIcon icon={faMedal} className="certification-icon" />
      <span>ISO 9001:2015, ISO 3834-2 and ISO 1090-2 certified</span>
    </div>
  );
};

export default Certification;
