const faqData = [
  {
    category: "General",
    items: [
      {
        question: "Is Steelify manufacturing my parts?",
        answer:
          "Steelify works with a network of local manufacturers to produce your parts, ensuring quality control while keeping transportation costs low and supply chains secure.",
      },
      {
        question: "Can I visit the workshop?",
        answer:
          "No, Steelify does not offer the option to visit the workshop. To ensure streamlined operations and maintain quality control, all processes, including production and delivery, are handled internally. However, we provide regular updates and inspections to ensure your satisfaction with the order.",
      },
      {
        question: "Do you put the customer into contact with the specific person for manufacturing?",
        answer:
          "For each project, we directly connect the customer with a dedicated engineer or the relevant production manager. This ensures that our clients receive detailed, first-hand information about the technical aspects and production process, facilitating the most efficient execution of their projects.",
      },
    ],
  },
  {
    category: "Quality and Assurance",
    items: [
      {
        question: "How can I be sure the quality is guaranteed?",
        answer:
          "Steelify ensures quality through partnerships with ISO 9001-certified manufacturers. All parts undergo strict quality control processes, and material certificates (such as EN10204/3.1) are available upon request to provide full transparency.",
      },
      {
        question: "How is my information protected?",
        answer:
          "Your information is safeguarded through our advanced security protocols. All files and data you upload are securely encrypted, and our manufacturing partners operate under strict confidentiality agreements. If needed, you can also request a Non-Disclosure Agreement (NDA) through your account manager for added protection.",
      },
    ],
  },
  {
    category: "Order and Payment",
    items: [
      {
        question: "How long do I have to wait to get a quote?",
        answer:
          "You can expect to receive a quote from Steelify within 24 hours, depending on the specifics of your request.",
      },
      {
        question: "Does the price per piece decrease as the quantity increases?",
        answer:
          "Yes, the cost per unit decreases as the order quantity increases. In high-volume production, fixed costs such as setup are distributed across more parts, optimizing the overall cost. This allows us to offer more economical solutions for larger orders.",
      },
      {
        question: "Does Steelify have any fees or subscriptions?",
        answer:
          "No, Steelify does not charge any subscription fees. You only pay for the services and products you order, with delivery fees included as part of the final cost. There are no hidden charges or additional fees.",
      },
    ],
  },
  {
    category: "Logistics and Delivery",
    items: [
      {
        question: "Do you clear customs on international orders?",
        answer:
          "Steelify handles customs clearance and covers import duties on orders shipped to the European Union. For all other shipping destinations, customers are responsible for any customs duties or import fees.",
      },
    ],
  },
  {
    category: "Customer Support",
    items: [
      {
        question: "How does Steelify handle requests and complaints?",
        answer:
          "Steelify handles requests and complaints through a dedicated customer support team. Each customer is assigned an account manager who ensures that all inquiries are addressed promptly and any issues are resolved efficiently to maintain high customer satisfaction.",
      },
    ],
  },
];

export default faqData;
