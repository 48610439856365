import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import FaqItem from "../../Components/Faq/faqItem";
import faqData from "../../Components/Faq/faqData";
import "./faqPage.css";

const { Content } = Layout;
const { Title } = Typography;

const FaqPage = () => {
  return (
    <Layout className="faq-page">
      <Content>
        <div className="faq-container">
          <Title level={2} className="faq-title">
            Frequently Asked Questions
          </Title>
          {faqData.map((category) => (
            <div key={category.category} className="faq-category">
              <Title level={3} className="faq-category-title">
                {category.category}
              </Title>
              <Row gutter={[16, 16]}>
                {category.items.map((item) => (
                  <Col xs={24} sm={24} md={12} key={item.question}>
                    <FaqItem question={item.question} answer={item.answer} />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Content>
    </Layout>
  );
};

export default FaqPage;