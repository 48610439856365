import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const FaqItem = ({ question, answer }) => {
  return (
    <Collapse
      accordion
      expandIconPosition="right"
      className="faq-item"
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined /> : <PlusOutlined />
      }
    >
      <Panel header={question} key={question}>
        <p>{answer}</p>
      </Panel>
    </Collapse>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FaqItem;
