import React from 'react';
import './headerSection.css';

const HeaderSection = () => {
    return (
            <div className="header-content">
                <h1>We deliver <span className="highlight">custom metal parts.</span></h1>
                <p>
                    Partner with trusted manufacturers to quickly supply <br />
                    high quality, competitively priced custom metal parts.
                </p>
            </div>
    );
};

export default HeaderSection;
