/* global gtag */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Card, Row, Col, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import GetQuoteForm from "../../Components/GetQuote/getQuoteForm";
import SuccessModal from "../../Components/GetQuote/successModal";
import { API_BASE_URL, CLIENT_BASE_URL } from "../../config";
import getOrderConfirmationEmailHtml from "../../EmailTemplates/OrderConfirmation/orderConfirmationMailTemplate";
import getOrderConfirmationToAdminEmailHtml from "../../EmailTemplates/OrderConfirmation/orderConfirmationToAdminTemplate";
import "./getQuotePage.css";

const { Content } = Layout;

const GetQuotePage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [orderData, setOrderData] = useState(null);

  // Kullanıcı bilgilerini Redux'tan alın
  const token = useSelector((state) => state.user.token);
  const email = useSelector((state) => state.user.email);
  const username = useSelector((state) =>
    state.user.user ? state.user.user.name : "Guest"
  );

  // Form gönderimini yönetin
  const handleSubmit = async (values, fileList, photoList) => {
    setIsSubmitting(true);
    const formData = new FormData();

    // Dosyaları ve fotoğrafları formData'ya ekleyin
    fileList.forEach((file) => {
      formData.append("orderDocs", file);
    });
    photoList.forEach((photo) => {
      formData.append("samplePhotos", photo);
    });

    // Form değerlerini formData'ya ekleyin
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    // gtag etkinliğini gönderen yardımcı fonksiyon
    const sendGtagEvent = () => {
      return new Promise((resolve, reject) => {
        const eventCallback = () => {
          // console.log("gtag event callback called");
          resolve();
        };

        try {
          if (typeof gtag === "function") {
            // console.log("gtag function exists, sending event");
            gtag("event", "conversion_event_purchase", {
              event_category: "Conversion",
              event_label: "purchase61",
              value: 1,
              event_callback: eventCallback,
              event_timeout: 2000,
            });
          } else {
            console.log("gtag function does not exist");
            resolve();
          }
        } catch (error) {
          console.error("Error in gtag event:", error);
          reject(error);
        }
      });
    };

    try {
      // Önce gtag etkinliğini gönder ve tamamlanmasını bekle
      await sendGtagEvent();

      // Form verilerini API'ye gönder
      const response = await axios.post(
        `${API_BASE_URL}/order/createOrder`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.message === "Successfully Created !") {
        setOrderData(response.data.data);

        // Kullanıcıya ve yöneticilere onay e-postası gönder
        const emailHtml = getOrderConfirmationEmailHtml(
          username,
          response.data.data.id,
          CLIENT_BASE_URL,
          response.data.data
        );

        const adminEmailHtml = getOrderConfirmationToAdminEmailHtml(
          response.data.data,
          username,
          email
        );

        await Promise.all([
          axios
            .post(`${API_BASE_URL}/email-sender/order-confirmation`, {
              to: email,
              subject: "We Have Received Your Request",
              html: emailHtml,
            })
            .catch((emailError) => {
              console.error(
                "Failed to send order confirmation email:",
                emailError
              );
              message.error("Failed to send order confirmation email.");
            }),

          axios
            .post(`${API_BASE_URL}/email-sender/order-notification-admin`, {
              subject: "New Order Created",
              html: adminEmailHtml,
            })
            .catch((emailError) => {
              console.error(
                "Failed to send admin notification email:",
                emailError
              );
              message.error("Failed to send admin notification email.");
            }),
        ]);

        setSuccessModalVisible(true);
        message.success(response.data.message);
      } else {
        console.log("Server response:", response.data);
        message.error("Failed to submit quote request.");
      }
    } catch (error) {
      console.log("Submit order error:", error);
      message.error(
        error.response?.data?.message || "Error submitting quote request."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Talep detayları sayfasına yönlendir
  const goToRequestDetails = () => {
    setIsSubmitting(false);
    navigate(`/request-details/${orderData ? orderData.id : ""}`);
  };

  return (
    <Layout className="get-quote-page-container">
      <Content>
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={22} xl={22}>
            <Card bordered={false} className="get-quote-card">
              <Spin spinning={isSubmitting} className="get-quote-spin">
                <GetQuoteForm onSubmit={handleSubmit} />
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
      <SuccessModal
        visible={successModalVisible}
        onOk={goToRequestDetails}
        className="get-quote-success-modal"
      />
    </Layout>
  );
};

export default GetQuotePage;
