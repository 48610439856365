import React from "react";
import "./trustpilot.css";

const Trustpilot = () => {
  return (
    <div className="trustpilot-container">
      <a href="https://www.trustpilot.com/review/steelify.com" target="_blank" rel="noopener noreferrer">
        <img
          src="https://yenastorage.blob.core.windows.net/steelify/steelify_image_trustpilot.png"
          alt="Trustpilot"
          className="trustpilot-image"
        />
      </a>
    </div>
  );
};

export default Trustpilot;
