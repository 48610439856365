import React, { useState } from 'react';
import { Modal, message } from 'antd';
import RegisterForm from '../Components/Register/registerForm';
import axios from 'axios';
import { API_BASE_URL, CLIENT_BASE_URL } from '../config';
import getWelcomeEmailHtml from '../EmailTemplates/Registiration/welcomeMailTemplate';
import getNewUserRegistrationToAdminEmailHtml from '../EmailTemplates/Registiration/newUserRegistrationToAdminTemplate';

const RegisterModal = ({ visible, onCancel }) => {
  const [loading, setLoading] = useState(false);

  // Mail gönderme fonksiyonu
  const sendEmails = async (values) => {
    const emailHtml = getWelcomeEmailHtml(values.name, CLIENT_BASE_URL);
    const adminEmailHtml = getNewUserRegistrationToAdminEmailHtml(values);

    console.log('Email sending started...'); // Log ekliyoruz

    try {
      await Promise.all([
        axios.post(`${API_BASE_URL}/email-sender/welcome`, {
          to: values.email,
          subject: 'Welcome to Steelify',
          html: emailHtml,
        }),
        axios.post(`${API_BASE_URL}/email-sender/new-user-registration`, {
          to: [
            'emre.mataraci@steelify.com',
            // Diğer admin e-posta adresleri eklenebilir
          ],
          subject: 'New User Registration',
          html: adminEmailHtml,
        }),
      ]);
    } catch (error) {
      console.error('Email sending error:', error); // Hata durumunu logluyoruz
      message.error('Failed to send emails. Please try again later.');
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, {
        email: values.email,
        password: values.password,
        userType: values.userType,
        website: values.website,
        name: values.name,
        surname: values.surname,
      });

      if (response.data) {
        console.log('User registration successful, now sending emails...'); // Kullanıcı kaydı başarılıysa logluyoruz
        message.success('Registration successful!');
        
        // E-postaları gönderme işlemi
        await sendEmails(values);

        onCancel(); // Kayıt başarılıysa modalı kapatıyoruz
      } else {
        message.error('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error); // Kayıt sırasında oluşan hatayı logluyoruz
      message.error(
        error.response?.data?.message || 'An error occurred during registration. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
      <h2>Register</h2>
      <RegisterForm onFinish={handleSubmit} loading={loading} />
    </Modal>
  );
};

export default RegisterModal;
