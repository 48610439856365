import { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { API_BASE_URL } from "../config";

const useQuoteDetails = (order_id, isPreFilled, accessToken, form) => {
  const [fileList, setFileList] = useState([]);
  const [photoList, setPhotoList] = useState([]);

  useEffect(() => {
    if (isPreFilled && order_id) {
      const fetchQuoteDetails = async () => {
        console.log("Fetching quote details with order_id:", order_id);
        
        try {
          const response = await axios.get(`${API_BASE_URL}/order/${order_id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });

          console.log("API Response:", response);

          if (response.data && response.data.data) {
            form.setFieldsValue({
              ...response.data.data,
              quotation_note: response.data.data.quotation_note,
            });

            setFileList(
              response.data.orderDocs.filename.map((name, index) => ({
                uid: index,
                name: name,
                status: "done",
                url: response.data.orderDocs.file_link[index],
              }))
            );

            setPhotoList(
              response.data.photos.filename.map((name, index) => ({
                uid: index,
                name: name,
                status: "done",
                url: response.data.photos.filelink[index],
              }))
            );
          } else {
            console.warn("Response does not contain expected data structure:", response.data);
            message.error("Failed to load quote details.");
          }
        } catch (error) {
          console.error("Failed to fetch quote details:", error);
          message.error("Failed to load quote details.");
        }
      };

      fetchQuoteDetails();
    }
  }, [order_id, isPreFilled, accessToken, form]);

  return { fileList, photoList, setFileList, setPhotoList };
};

export default useQuoteDetails;
