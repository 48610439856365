import React, { useState } from "react";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const PhotoUpload = ({ onPhotoListChange }) => {
  const [photoList, setPhotoList] = useState([]);

  const handlePhotoChange = ({ fileList: newPhotoList }) => {
    const filteredList = newPhotoList.filter((file) => {
      if (file.type.startsWith("image/")) {
        return true;
      } else {
        message.error(`${file.name} is not an image file.`, 10);
        return false;
      }
    });

    if (filteredList.length > 10) {
      message.error("You can only upload up to 10 photos.", 10);
      filteredList.length = 10;
    }

    setPhotoList(
      filteredList.map((file) =>
        file.originFileObj ? file.originFileObj : file
      )
    );
    onPhotoListChange(filteredList);
  };

  return (
    <Upload
      name="samplePhotos"
      listType="picture"
      multiple={true}
      accept=".jpg,.jpeg,.png"
      beforeUpload={() => false}
      onChange={handlePhotoChange}
      fileList={photoList}
    >
      <Button icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>
  );
};

export default PhotoUpload;
