import React from "react";
import "./welding.css";
import { Link } from "react-router-dom";
// import Trustpilot from "../../Homepage/Trustpilot/trustpilot"; // Yorum satırı yapıldı

function Welding() {
  return (
    <div className="welding-container">
      <div className="welding-left">
        <h1>Welding</h1>
        <div className="welding-content">
          <p>
            We offer high-quality welding services using advanced techniques to
            ensure strong, precise results, meeting the diverse needs of your
            business.
          </p>
          <ul>
            <li>Specializing in MIG, TIG, and MAG welding</li>
            <li>
              Reliable welds for both small and large projects, delivered on
              time
            </li>
            <li>Adherence to industry standards for safety and strength</li>
          </ul>
        </div>
        {/* <div className="capabilities-trustpilot-container">
          <Trustpilot />
        </div> */}
        <div className="welding-call-to-action">
          <p className="call-to-action-text">
            Need reliable welding services for your next project? Contact us today for a custom quote and ensure your work is completed with strength and precision.
          </p>
          <Link to="/" className="call-to-action-button">
            Get Your Quote!
          </Link>
        </div>
      </div>
      <div className="welding-right">
        <img
          src="https://yenastorage.blob.core.windows.net/steelify/steelify_image_welding.png"
          alt="Welding"
        />
      </div>
    </div>
  );
}

export default Welding;
