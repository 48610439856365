import React from "react";
import MyRequestsContainer from "../../Components/MyRequests/myRequestsContainer";

const MyRequestsPage = () => {
  return (
    <div>
      <MyRequestsContainer />
    </div>
  );
};

export default MyRequestsPage;
