import React from "react";
import "./cncMachining.css";
import { Link } from "react-router-dom";
// import Trustpilot from "../../Homepage/Trustpilot/trustpilot"; // Yorum satırı yapıldı

function CNCMachining() {
  return (
    <div className="cnc-container">
      <div className="cnc-left">
        <h1>CNC Machining</h1>
        <div className="cnc-content">
          <p>
            Our CNC machining delivers accurate, custom-made parts with
            consistent quality, tailored to your project specifications.
          </p>
          <ul>
            <li>Precision milling and turning for complex components</li>
            <li>
              Reliable production for both prototypes and large-scale
              manufacturing
            </li>
            <li>Exact tolerances, customized to your project's needs</li>
          </ul>
        </div>
        {/* <div className="capabilities-trustpilot-container">
          <Trustpilot />
        </div> */}
        <div className="cnc-call-to-action">
          <p className="call-to-action-text">
            Ready to start your CNC machining project? Get in touch with us today, and let’s bring your designs to life with precision.
          </p>
          <Link to="/" className="call-to-action-button">
            Get Your Quote!
          </Link>
        </div>
      </div>
      <div className="cnc-right">
        <img
          src="https://yenastorage.blob.core.windows.net/steelify/steelify_image_cnc_machining.png"
          alt="CNC Machining"
        />
      </div>
    </div>
  );
}

export default CNCMachining;
