import React, { useState } from "react";
import "./completedWorks.css";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const sectors = [
  {
    name: "Machine Parts",
    images: [
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_machine_parts_img1.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_machine_parts_img2.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_machine_parts_img3.png"
    ]
  },
  {
    name: "Spools and Vessels",
    images: [
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_spools_and_vessels_img1.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_spools_and_vessels_img2.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_spools_and_vessels_img3.png"
    ]
  },
  {
    name: "Temporary Buildings",
    images: [
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_temporary_buildings_img1.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_temporary_buildings_img2.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_temporary_buildings_img3.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_temporary_buildings_img4.png"
    ]
  },
  {
    name: "Storage Systems",
    images: [
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_storage_systems_img1.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_storage_systems_img2.png",
      "https://yenastorage.blob.core.windows.net/steelify/steelify_image_storage_systems_img3.png"
    ]
  }
];

const CompletedWorks = () => {
  const [selectedSector, setSelectedSector] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleNext = () => {
    if (selectedImage < sectors[selectedSector].images.length - 1) {
      setSelectedImage((prev) => prev + 1);
    } else {
      if (selectedSector < sectors.length - 1) {
        setSelectedSector((prev) => prev + 1);
        setSelectedImage(0);
      }
    }
  };

  const handlePrevious = () => {
    if (selectedImage > 0) {
      setSelectedImage((prev) => prev - 1);
    } else {
      if (selectedSector > 0) {
        setSelectedSector((prev) => prev - 1);
        setSelectedImage(sectors[selectedSector - 1].images.length - 1);
      }
    }
  };

  const handleSectorChange = (index) => {
    setSelectedSector(index);
    setSelectedImage(0);
  };

  return (
    <div className="completed-works-container">
      <div className="sectors">
        <h2>Completed Works</h2>
        <p>Sectors</p>
        <ul>
          {sectors.map((sector, index) => (
            <li
              key={index}
              className={selectedSector === index ? "active-sector" : ""}
              onClick={() => handleSectorChange(index)}
            >
              {sector.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="image-display">
        <button className="nav-button prev-button" onClick={handlePrevious}>
          <LeftOutlined />
        </button>
        <img
          src={sectors[selectedSector].images[selectedImage]}
          alt={`${sectors[selectedSector].name} ${selectedImage + 1}`}
          className="completed-work-image"
        />
        <button className="nav-button next-button" onClick={handleNext}>
          <RightOutlined />
        </button>
      </div>
    </div>
  );
};

export default CompletedWorks;
