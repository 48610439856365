import React from "react";
import { Link } from "react-router-dom";
import "./capabilities.css";

const capabilitiesData = [
  {
    title: "Welding",
    image:
      "https://yenastorage.blob.core.windows.net/steelify/steelify_welding_square.png",
    link: "/welding",
  },
  {
    title: "CNC Machining",
    image:
      "https://yenastorage.blob.core.windows.net/steelify/steelify_CNC_square.png",
    link: "/cnc-machining",
  },
  {
    title: "Bending",
    image:
      "https://yenastorage.blob.core.windows.net/steelify/steelify_bending_square.png",
    link: "/bending",
  },
  {
    title: "Laser Cutting",
    image:
      "https://yenastorage.blob.core.windows.net/steelify/steelify_laser_square.png",
    link: "/laser-cutting",
  },
];

const Capabilities = () => {
  return (
    <div className="capabilities-container">
      <h2 className="capabilities-title">Capabilities</h2>
      {capabilitiesData.map((capability, index) => (
        <div key={index} className="capability-item">
          <Link to={capability.link} className="capability-link">
            <div className="capability-icon-container">
              <img
                src={capability.image}
                alt={capability.title}
                className="capability-icon"
              />
            </div>
            <p className="capability-title">{capability.title}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Capabilities;
