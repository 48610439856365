import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, message, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import FileUpload from "./fileUpload";
import PhotoUpload from "./photoUpload";
import LoginModal from "../../Modals/loginModal";
import useQuoteDetails from "../../Hooks/useQuoteDetails";
import "./getQuoteForm.css";

const { Title } = Typography;

const GetQuoteForm = ({ onSubmit, order_id, isPreFilled = false }) => {
  const [form] = Form.useForm();
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const userInfo = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.token);
  const { fileList, photoList, setFileList, setPhotoList } = useQuoteDetails(
    order_id,
    isPreFilled,
    null,
    form
  );

  // Handle file upload changes
  const handleFileListChange = (newFileList) => {
    setFileList(newFileList);
  };

  // Handle photo upload changes
  const handlePhotoListChange = (newPhotoList) => {
    setPhotoList(newPhotoList);
  };

  // Handle login success and close the modal
  const handleLoginSuccess = () => {
    setIsLoginModalVisible(false);
  };

  // Form submission logic
  const onFinish = (values) => {
    if (!userInfo) {
      // If user is not logged in, show login modal
      message.error("Please login to continue.");
      setIsLoginModalVisible(true);
      return;
    }

    const submissionData = {
      ...values,
      user_id: userInfo.id,
      name: userInfo.name,
      customer: userInfo.name,
      incoterm: "-",
      paymentterm: "-",
      incoterm_description: "-",
      quotation_note: values.quotation_note || "-",
      delivery_date: "2024-02-25",
      status: "Pending Review",
      reference: values.requestNo || "-",
      filename: "-",
      target_price: values.target_price || null,
    };

    // Call onSubmit to send the form data
    onSubmit(submissionData, fileList, photoList);
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish} className="get-quote-form">
        <Title level={3} className="form-title">
          Get your quote instantly
        </Title>

        <Form.Item>
          <FileUpload onFileListChange={handleFileListChange} />
        </Form.Item>

        <div className="upload-security">
          <FontAwesomeIcon icon={faLock} className="lock-icon" />
          <span>All uploads are secure and confidential.</span>
        </div>

        <Form.Item
          name="quotation_note"
          label="Details"
          rules={[{ required: true, message: "Please enter your project/product description!" }]}
        >
          <Input.TextArea rows={4} placeholder="Product Requirements / Delivery Time / Quantity / etc." />
        </Form.Item>

        <Form.Item name="requestNo" label="Request No (Optional)">
          <Input placeholder="Enter your request no" />
        </Form.Item>

        <Form.Item name="target_price" label="Target Price (Optional)">
          <Input placeholder="Enter your target price" />
        </Form.Item>

        <Form.Item
          name="productPhotos"
          label="Product Photos"
          extra="Packaging or in-built photos will help us to accelerate the quotation process."
        >
          <PhotoUpload onPhotoListChange={handlePhotoListChange} />
        </Form.Item>

        <Form.Item>
          <div className="submit-container">
            <Button type="primary" htmlType="submit">
              Get Instant Quote
            </Button>
          </div>
        </Form.Item>
      </Form>

      <LoginModal
        visible={isLoginModalVisible}
        onCancel={() => setIsLoginModalVisible(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default GetQuoteForm;